import React, { Fragment, useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { FormattedDate, useIntl } from 'react-intl'
import { DeviceChannelInfoDto } from 'pages/devices/data/iDevicesList'
import Show from 'components/atom/Show'
import DeviceImage from 'components/viewComponents/DeviceImage'
import { EditIcon } from 'assets/icons'
import { Dialog, Transition } from '@headlessui/react'
import EditDevice from 'pages/devices-2/view/EditDevice'
import { DeviceCalDueTypes, UserRoleTypes } from 'utils/CommonEnums'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import withRoleChecking from 'hoc/withRoleChecking'
import Tooltip from 'components/atom/Tooltip'
import { DeviceDetailsContext } from './DeviceDetailsContext'
import { iBumpedStatus, iDeviceDetails } from '../data/interface'

interface DeviceDetailsAdditionalInfoProps {
  deviceDetails?: iDeviceDetails
}

function LoaderWithDelay({ deviceLoader }: { deviceLoader?: boolean }) {
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Show>
      <Show.When isTrue={showLoader}>
        <Show>
          <Show.When isTrue={Boolean(deviceLoader)}>
            <div className='flex h-full flex-col justify-center items-center'>
              <div className='w-full h-full  pt-1.5 pb-1.5 rounded-lg bg-c-dark-blue-2 animate-pulse' />
              <div className='flex flex-row mt-2 items-center justify-between w-full'>
                <div className='w-1/3 h-[24px]  pt-1.5 pb-1.5 rounded-lg bg-c-dark-blue-2 animate-pulse' />
                <div className='w-[30px] h-[30px]  pt-1.5 pb-1.5 rounded-full bg-c-dark-blue-2 animate-pulse' />
              </div>
            </div>
          </Show.When>
          <Show.Otherwise>
            <div className='py-18 px-2 md:px-18 w-full'>
              <div className='w-1/2 h-[20px] rounded-lg bg-c-dark-blue-2 animate-pulse mb-[10px]' />

              <div className='grid grid-cols-1 gap-x-3 gap-y-1 h-auto '>
                <div className='col-span-1  '>
                  <div className=' h-26 pt-1.5 pb-1.5 rounded-lg bg-c-dark-blue-2 animate-pulse' />
                </div>
                <div className='col-span-1  '>
                  <div className=' h-26 pt-1.5 pb-1.5 rounded-lg bg-c-dark-blue-2 animate-pulse' />
                </div>
                <div className='col-span-1  '>
                  <div className='h-26 pt-1.5 pb-1.5 rounded-lg bg-c-dark-blue-2 animate-pulse' />
                </div>
              </div>
            </div>
          </Show.Otherwise>
        </Show>
      </Show.When>
      <Show.Otherwise>
        <div className='py-18 px-2 md:px-18 w-full text-xs font-bold leading-4  text-c-dark-blue-2  text-ellipsis'>
          Not Available
        </div>
      </Show.Otherwise>
    </Show>
  )
}

LoaderWithDelay.defaultProps = {
  deviceLoader: false,
}

function CalibrationOverDue({ calibrationDueDate }: { calibrationDueDate: string }) {
  function calculateOverdue(): DeviceCalDueTypes {
    if (calibrationDueDate) {
      const currentDate = new Date()
      const dueDate = new Date(calibrationDueDate)

      const timeDifference = dueDate.getTime() - currentDate.getTime()
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24))

      switch (true) {
        case daysDifference > 90:
          return DeviceCalDueTypes.DueOver90Days
        case daysDifference > 60:
          return DeviceCalDueTypes.DueIn90Days
        case daysDifference > 30:
          return DeviceCalDueTypes.DueIn60Days
        case daysDifference > 7:
          return DeviceCalDueTypes.DueIn30Days
        case daysDifference > 0:
          return DeviceCalDueTypes.DueIn7Days
        default:
          return DeviceCalDueTypes.Overdue
      }
    } else {
      return DeviceCalDueTypes.NotAvailable
    }
  }
  const value = calculateOverdue()

  return (
    <Show>
      <Show.When isTrue={value !== DeviceCalDueTypes.NotAvailable}>
        <Tooltip
          toolTipClass='tooltip z-50 -top-8 -right-8 px-4'
          tooltipText={`${value} - ${calibrationDueDate?.split('T')?.[0]}`}
        >
          <span
            className={clsx('whitespace-nowrap rounded-full float-right  text-xs text-ellipsis ', {
              'text-green-500 font-semibold': value === DeviceCalDueTypes.DueOver90Days,
              'text-c-green font-semibold ': value === DeviceCalDueTypes.DueIn90Days,
              'text-c-blue font-semibold': value === DeviceCalDueTypes.DueIn60Days,
              'text-blue-500 font-semibold': value === DeviceCalDueTypes.DueIn30Days,
              'text-c-orange font-semibold': value === DeviceCalDueTypes.DueIn7Days,
              'text-c-red-1 font-semibold': value === DeviceCalDueTypes.Overdue,
              'text-c-dark-blue-2  font-medium ': value === DeviceCalDueTypes.NotAvailable,
            })}
          >
            {value}
          </span>
        </Tooltip>
      </Show.When>
      <Show.Otherwise>
        <span
          className={clsx('whitespace-nowrap rounded-full float-right  text-xs text-ellipsis', {
            'text-c-dark-blue-2  font-medium ': value === DeviceCalDueTypes.NotAvailable,
          })}
        >
          {value}
        </span>
      </Show.Otherwise>
    </Show>
  )
}

function DeviceStatusCheck({ status }: { status: string }) {
  const isActive = status === 'Active'

  return (
    <div className='text-xs font-bold float-right'>
      <Show>
        <Show.When isTrue={isActive}>
          <span className='text-green-600 font-semibold text-ellipsis'>Active</span>
        </Show.When>
        <Show.Otherwise>
          <span className='text-c-red-1  font-semibold text-ellipsis'>Inactive</span>
        </Show.Otherwise>
      </Show>
    </div>
  )
}
function BumpedTestResult({ bumpStatus }: { bumpStatus?: iBumpedStatus }) {
  const intl = useIntl()
  return (
    <div className='text-xs float-right'>
      <Show>
        <Show.When isTrue={Boolean(bumpStatus)}>
          <Show>
            <Show.When isTrue={bumpStatus?.bumpStatus === 'BumpTestPassed'}>
              <Tooltip
                toolTipClass='tooltip z-50 -top-8 -right-8 px-4'
                tooltipText={`Passed - ${intl.formatDate(bumpStatus?.lastBumpTestOn as string)}`}
              >
                <span className='text-green-600 font-semibold text-ellipsis'>
                  {intl.formatDate(bumpStatus?.lastBumpTestOn as string)}
                </span>
              </Tooltip>
            </Show.When>
            <Show.Otherwise>
              <Tooltip
                toolTipClass='tooltip z-50 -top-8 -right-8 px-4'
                tooltipText={`Failed - ${intl.formatDate(bumpStatus?.lastBumpTestOn as string)}`}
              >
                <span className='text-c-red-1  font-semibold text-ellipsis'>
                  {intl.formatDate(bumpStatus?.lastBumpTestOn as string)}
                </span>
              </Tooltip>
            </Show.Otherwise>
          </Show>
        </Show.When>
        <Show.Otherwise>
          <span className=' font-medium text-ellipsis'>NA</span>
        </Show.Otherwise>
      </Show>
    </div>
  )
}

BumpedTestResult.defaultProps = {
  bumpStatus: undefined,
}

function DeviceDetailsAdditionalInfo(deviceDetailProps: DeviceDetailsAdditionalInfoProps) {
  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { state } = deviceDetailsContext
  const { deviceDetails } = deviceDetailProps
  const [isOpenEditForm, setIsOpenEditForm] = useState<boolean>(false)
  const intl = useIntl()
  const EditDeviceButton = withRoleChecking(
    () => (
      <div aria-hidden='true' onClick={() => setIsOpenEditForm(true)}>
        <div className='flex p-1 justify-center items-center rounded-full  bg-c-dark-blue-1 hover:cursor-pointer hover:bg-c-dark-blue-2 focus:border-1  focus:border-c-dark-blue-3 focus:bg-c-dark-blue-3 active:bg-c-dark-blue-3'>
          <EditIcon fill='#FFF' />
        </div>
      </div>
    ),
    [UserRoleTypes.User, UserRoleTypes.CustomerAdmin.split(' ').join(''), UserRoleTypes.Admin],
  )
  return (
    <>
      <div
        id='deviceDetailsAdditionalInfo'
        className={clsx(
          'bg-c-light-blue-2 p-4 grid grid-cols-3  md:grid-cols-4  lg:grid-cols-5 xl:grid-cols-8 w-full grid-rows-none gap-x-4 gap-y-2',
          state.filterFormShown && '',
        )}
      >
        <div className='col-span-1  md:col-span-1 lg:col-span-1 xl:col-span-1 bg-white rounded-xl  px-4  py-4  card-shadow '>
          <Show>
            <Show.When isTrue={Boolean(deviceDetails)}>
              <div className=' flex flex-col'>
                <DeviceImage
                  deviceType={deviceDetails?.deviceType}
                  className='!p-0 h-[6rem] w-[7.5rem]'
                />
                <div className=' w-full flex flex-row  items-center justify-between '>
                  <div className='font-semibold text-[1rem] text-ellipsis max-w-[100px]'>
                    {deviceDetails?.deviceType}
                  </div>

                  <EditDeviceButton />
                </div>
              </div>
            </Show.When>
            <Show.Otherwise>
              <LoaderWithDelay deviceLoader />
            </Show.Otherwise>
          </Show>
        </div>

        <div className='col-span-2 md:col-span-2 xl:col-span-2 bg-white rounded-xl  card-shadow'>
          <Show>
            <Show.When isTrue={Boolean(deviceDetails)}>
              <GenericWidget
                widgetProps={{
                  cardContainerClassName:
                    'flex flex-col lg:flex-wrap bg-c-white rounded-2xl py-18 px-2 md:px-18 w-full',
                  title: 'Device Detail',
                  widgetData: [
                    {
                      displayText: 'Status',
                      originalData: deviceDetails?.status || 'NA',
                      value: <DeviceStatusCheck status={deviceDetails?.status as string} />,
                      gridItemClassName: 'col-span-2 ',
                    },
                    {
                      displayText: 'Asset Number',
                      originalData: deviceDetails?.customerAssetNumber || 'NA',
                      value: deviceDetails?.customerAssetNumber || 'NA',
                      gridItemClassName: 'col-span-2',
                    },
                    {
                      displayText: 'Assigned To',
                      originalData: deviceDetails?.deviceUser?.fullName || 'NA',
                      value: deviceDetails?.deviceUser?.fullName || 'NA',
                      gridItemClassName: 'col-span-2',
                    },
                  ],
                  widgetLabelCount: 3,
                }}
              />
            </Show.When>

            <Show.Otherwise>
              <LoaderWithDelay />
            </Show.Otherwise>
          </Show>
        </div>
        <div className='col-span-3 md:col-span-2 xl:col-span-2 bg-white rounded-xl card-shadow'>
          <Show>
            <Show.When isTrue={Boolean(deviceDetails)}>
              <GenericWidget
                widgetProps={{
                  cardContainerClassName:
                    'flex flex-col lg:flex-wrap bg-c-white rounded-2xl py-18 px-2 md:px-18 w-full',
                  title: 'Calibration Detail',
                  widgetData: [
                    {
                      displayText: 'Calibration Due',
                      originalData: (deviceDetails?.calibrationDueDate || 'NA') as string,
                      value: (
                        <CalibrationOverDue
                          calibrationDueDate={deviceDetails?.calibrationDueDate as string}
                        />
                      ),
                      gridItemClassName: 'col-span-2 relative',
                    },
                    {
                      displayText: 'Last Calibrated Date',
                      originalData: (deviceDetails?.lastCalibratedDate || 'NA') as string,
                      value: ((deviceDetails?.lastCalibratedDate &&
                        intl.formatDate(deviceDetails?.lastCalibratedDate)) ||
                        'NA') as unknown as number,
                      gridItemClassName: 'col-span-2',
                    },
                    {
                      displayText: 'Last Bumped',
                      originalData: (deviceDetails?.bumpStatus?.lastBumpTestOn || 'NA') as string,
                      value: <BumpedTestResult bumpStatus={deviceDetails?.bumpStatus} />,
                      gridItemClassName: 'col-span-2 relative',
                    },
                  ],
                  widgetLabelCount: 3,
                }}
              />
            </Show.When>

            <Show.Otherwise>
              <LoaderWithDelay />
            </Show.Otherwise>
          </Show>
        </div>
        <div className='col-span-3 md:row-start-1 md:col-start-2 md:col-span-3 lg:row-auto lg:col-span-5 xl:col-span-3 bg-white rounded-xl card-shadow'>
          <Show>
            <Show.When isTrue={Boolean(deviceDetails)}>
              <GenericWidget
                widgetProps={{
                  title: 'Channel Config',
                  cardContainerClassName:
                    'flex flex-col lg:flex-wrap bg-c-white rounded-2xl py-18 px-2 md:px-18 w-full',
                  gridContainerClassName: ' lg:grid-flow-col gap-1',
                  widgetData:
                    deviceDetails?.channels && deviceDetails.channels?.length > 0
                      ? deviceDetails?.channels.map((channel: DeviceChannelInfoDto, index) => ({
                          displayText: channel.channelNumber as unknown as string,
                          originalData: channel.channelName,
                          value: channel.channelName as unknown as number,
                          gridItemClassName:
                            (deviceDetails?.channels?.length as number) === 1
                              ? `col-span-2 `
                              : `col-span-1 ${index > 2 ? 'lg:col-start-2 ' : 'lg:col-start-1'}`,
                        }))
                      : [
                          {
                            displayText: 'Not Available',
                            originalData: 'Not Available',
                            value: '' as unknown as number,
                            gridItemClassName: 'col-span-2',
                          },
                        ],
                  widgetLabelCount: 0,
                }}
              />
            </Show.When>

            <Show.Otherwise>
              <LoaderWithDelay />
            </Show.Otherwise>
          </Show>
        </div>
      </div>
      <Show.When isTrue={isOpenEditForm}>
        <Transition appear show={isOpenEditForm} as={Fragment}>
          <Dialog as='div' onClose={() => setIsOpenEditForm(false)}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black/25 z-50' />
            </Transition.Child>

            <div className='fixed inset-0 overflow-y-auto z-50'>
              <div className='flex min-h-full items-center justify-center p-4 text-center'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Dialog.Panel className=''>
                    <EditDevice
                      isOpen={isOpenEditForm}
                      setIsOpened={() => setIsOpenEditForm(false)}
                      rowData={
                        deviceDetails && {
                          id: deviceDetails?.id || '',
                          serialNumber: deviceDetails?.serialNumber,
                          deviceType: deviceDetails?.deviceType,
                          customerId: deviceDetails?.customerId,
                          customerAssetNumber: deviceDetails?.customerAssetNumber,
                          lastCalibratedDate: deviceDetails?.lastCalibratedDate as string,
                          calibrationDueDate: deviceDetails?.calibrationDueDate as string,
                          businessUnit: deviceDetails?.businessUnit,
                          deviceUser: deviceDetails?.deviceUser,
                          channels: deviceDetails?.channels,
                          status: deviceDetails?.status,
                          Note: '',
                          lastSwitchOnDate: '',
                          lastUploadDate: '',
                          calDueStatus: DeviceCalDueTypes.NotAvailable,
                        }
                      }
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </Show.When>
    </>
  )
}

export default withErrorBoundary(DeviceDetailsAdditionalInfo, {
  FallbackComponent: ErrorPage,
})
