import React, { Component, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useMsal } from '@azure/msal-react'
import { useLocation } from 'react-router-dom'

import DeviceImage from 'components/viewComponents/DeviceImage'
import { PortableTypes } from 'utils/CommonEnums'
import { LoaderPing } from 'assets/icons'
import { useDeviceConfig } from 'data/DeviceConfigHook'
import { tr } from 'date-fns/locale'
import { useGenericEventHandler } from 'data/GenericEventHandler'

export interface iDeviceConfigSubRowProps {
  deviceId: string
}

function DeviceConfigSubRow(props: iDeviceConfigSubRowProps): JSX.Element {
  const { deviceId } = props
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const { genericEventHandler } = useGenericEventHandler()

  const {
    data: deviceConfigData,
    isLoading: isDeviceConfigDataLoading,
    isFetched: deviceConfigDataFetched,
    isError: deviceConfigDataError,
    error: deviceConfigDataErrorDetails,
  } = useDeviceConfig(redirectPageURL, deviceId, true)

  useEffect(() => {
    if (deviceConfigDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceConfigDataErrorDetails?.message || 'Device Configuration data not available',
        error: deviceConfigDataErrorDetails,
        extraData: {
          component: 'DeviceConfigSubRow',
          action: 'fetching device configuration data',
        },
      })
    }
  }, [deviceConfigDataError, deviceConfigDataErrorDetails])
  const deviceType = deviceConfigData?.deviceProdType

  return (
    <div className=' '>
      {isDeviceConfigDataLoading ? (
        <div className='flex flex-row justify-start items-center align-middle'>
          <div className='ml-2 h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
        </div>
      ) : deviceConfigDataError ? (
        <div className='ml-5 mb-5 text-2xs text-c-red-1 font-medium flex flex-row justify-start items-center align-middle'>
          Configuration data not available for this device
        </div>
      ) : (
        <div className='flex flex-row pt-5 pb-5 '>
          <div className='w-36 h-24 '>
            <DeviceImage deviceType={deviceType} className='h-24 w-36 ' />
          </div>

          <div className='text-xs flex  flex-row'>
            <div className='w-20 font-bold'>
              <div className='mr-1 mb-2 pt-2 border-t-2 border-c-light-blue-3'>Channels</div>
              <div className='mr-1 mb-2 '>Gases</div>
              <div className='mr-1 mb-2 '>Alarm 1</div>
              <div className='mr-1 mb-2 '>Alarm 2</div>
            </div>
            {deviceConfigData?.deviceChanelConfigDetails?.map((channelConfig) => (
              <div key={channelConfig.channelNumber} className='w-20  text-xs'>
                <div className='mr-1 mb-2 pt-2 font-bold border-t-2 border-c-light-blue-3'>
                  {channelConfig.channelNumber}
                </div>
                <div className='mr-1 mb-2 '>{channelConfig.sensorDisplayName}</div>
                <div className='mr-1 mb-2 '>
                  {channelConfig.alarm1Level + channelConfig.sensorUoM}
                </div>
                <div className='mr-1 mb-2 '>
                  {channelConfig.alarm2Level + channelConfig.sensorUoM}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default DeviceConfigSubRow
