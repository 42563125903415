import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { PortableTypes } from 'utils/CommonEnums'
import deviceBlur from 'assets/images/deviceBlur.png'
import gasman from 'assets/images/crowcon-gasman.webp'
import gasPro from 'assets/images/crowcon-gaspro.webp'
import t3 from 'assets/images/crowcon-t3.webp'
import t4 from 'assets/images/crowcon-t4.webp'
import scout from 'assets/images/scout.webp'
import clip from 'assets/images/clip.webp'
// import gasmanSmall from 'assets/images/crowcon-small-gasman.webp'
// import gasProSmall from 'assets/images/crowcon-small-gaspro.webp'
// import t3Small from 'assets/images/crowcon-small-t3.webp'
// import t4Small from 'assets/images/crowcon-small-t4.webp'

interface DeviceImageProps {
  deviceType?: PortableTypes
  className?: string
}

const deviceImageMap = {
  [PortableTypes.Gasman]: gasman,
  [PortableTypes.GasPro]: gasPro,
  [PortableTypes.T3]: t3,
  [PortableTypes.T4]: t4,
  [PortableTypes.Scout]: scout,
  [PortableTypes.Clip]: clip,
}

function DeviceImage(props: DeviceImageProps): JSX.Element {
  const { deviceType, className = '' } = props
  return (
    <div className='w-full h-full  flex justify-center items-center'>
      {deviceType ? (
        <img
          className={`mix-blend-multiply  object-contain ${className}`}
          decoding='async'
          loading='lazy'
          src={deviceImageMap[deviceType]}
          alt='Device'
          // height='100%'
          // width='100%'
        />
      ) : (
        <div className='w-full h-full animate-pulse' />
      )}
    </div>
  )
}

export default DeviceImage
